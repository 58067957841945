import styled from '@emotion/styled';
import { FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import { classNames } from 'primereact/utils';
import * as React from 'react';
import { Controller, ControllerFieldState, FieldValues } from 'react-hook-form';

import { breakpoint, colors, fonts } from '../../styles/theme';
import Spinner from '../Spinner';
import { ButtonSubmit } from '../UI/Button/legacy/button/Button';
import { formData } from './constants/constants';
import useMeetUsForm from './hooks/useMeetUsForm';

const Row = styled.div`
    display: flex;
    gap: 8px;
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 16px;
    }
`;

const StyledTextField = styled(TextField)`
    border-color: ${colors.$gray300};
    border-radius: 4px;
    width: 100%;

    label {
        color: ${colors.$gray400};
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const ErrorMessage = styled.div`
    color: red;
    align-self: center;
    font-size: 12px;
    margin-bottom: -12px;
`;

const StyledButton = styled(ButtonSubmit)`
    align-self: self-start;

    ${breakpoint['$xlarge-max']} {
        width: 100%;
    }
`;

const StyledTextFieldResponsive = styled(StyledTextField)<{ showMobile: boolean }>`
    display: ${props => (props.showMobile ? 'none' : 'inherit')};
    ${breakpoint['$large-max']} {
        display: ${props => (props.showMobile ? 'inherit' : 'none')};
    }
`;

const MentionRGPD = styled.span`
    color: ${colors.$gray};

    font-family: ${fonts.OpenSans_Regular};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 16.5px */

    margin-top: -8px;

    a {
        color: ${colors.$gray};
        font-family: ${fonts.OpenSans_Regular};
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration-line: underline;
    }
`;

export default function MeetUsForm(): JSX.Element {
    const { control, moreDetail, errors, isSubmitting, onSubmit, handleSubmit } = useMeetUsForm();

    return (
        <div className="card flex justify-content-center">
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: true, minLength: 2 }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                error={!!fieldState.error}
                                variant="outlined"
                                size="medium"
                                label="Prénom"
                                value={field.value}
                                inputRef={field.ref}
                                onChange={e => field.onChange(e.target.value)}
                            />
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        rules={{ required: true, minLength: 2 }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                variant="outlined"
                                size="medium"
                                label="Nom"
                                error={!!fieldState.error}
                                value={field.value}
                                inputRef={field.ref}
                                className={classNames({ 'p-invalid': fieldState.error })}
                                onChange={e => field.onChange(e.target.value)}
                            />
                        )}
                    />
                </Row>
                <Row>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: 'Required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'invalid email address',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                fullWidth
                                variant="outlined"
                                size="medium"
                                label="Email"
                                type="email"
                                error={!!fieldState.error}
                                value={field.value}
                                inputRef={field.ref}
                                className={classNames({ 'p-invalid': fieldState.error })}
                                onChange={e => field.onChange(e.target.value)}
                            />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        rules={{ required: true, minLength: 10 }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                fullWidth
                                variant="outlined"
                                size="medium"
                                label="Téléphone"
                                type="number"
                                error={!!fieldState.error}
                                value={field.value}
                                inputRef={field.ref}
                                className={classNames({ 'p-invalid': fieldState.error })}
                                onChange={e => field.onChange(e.target.value)}
                            />
                        )}
                    />
                </Row>

                <Row>
                    <Controller
                        name="activity"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                select
                                value={field.value}
                                label="Secteur d’activité"
                                size="medium"
                                name="city"
                                error={!!fieldState.error}
                                onChange={e => field.onChange(e.target.value)}
                            >
                                {formData.activities.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </StyledTextField>
                        )}
                    />
                    {moreDetail === 'Autre' ? (
                        <Controller
                            name="activityFreeInput"
                            control={control}
                            rules={{ required: true, minLength: 2 }}
                            render={({ field, fieldState }) => (
                                <StyledTextField
                                    variant="outlined"
                                    size="medium"
                                    label="Precisez votre activité"
                                    error={!!fieldState.error}
                                    value={field.value}
                                    inputRef={field.ref}
                                    onChange={e => field.onChange(e.target.value)}
                                />
                            )}
                        />
                    ) : (
                        <Controller
                            name="location"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <StyledTextField
                                    select
                                    value={field.value}
                                    label="Domiciliation de la société"
                                    size="medium"
                                    name="city"
                                    error={!!fieldState.error}
                                    onChange={e => field.onChange(e.target.value)}
                                >
                                    {formData.cities.map(item => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </StyledTextField>
                            )}
                        />
                    )}
                </Row>
                {moreDetail === 'Autre' && (
                    <Controller
                        name="location"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState }) => (
                            <StyledTextField
                                select
                                value={field.value}
                                label="Domiciliation de la société"
                                size="medium"
                                name="city"
                                error={!!fieldState.error}
                                onChange={e => field.onChange(e.target.value)}
                            >
                                {formData.cities.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </StyledTextField>
                        )}
                    />
                )}

                {/* Logic for showing the correct selector in mobile*/}

                <Controller
                    name="serviceOfInterest"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <StyledTextFieldResponsive
                            showMobile
                            select
                            value={field.value}
                            label="Service recherché"
                            size="medium"
                            name="Service recherché"
                            error={!!fieldState.error}
                            onChange={e => field.onChange(e.target.value)}
                        >
                            {formData.services.map(item => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </StyledTextFieldResponsive>
                    )}
                />

                <Controller
                    name="serviceOfInterest"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                        <RadioSelectorField field={field} hideMobile fieldState={fieldState} />
                    )}
                />

                <MentionRGPD>
                    Dougs utilise les informations fournies pour vous permettre d’utiliser nos services, vous proposer
                    des informations et contenu pertinents afin de simplifier votre vie d’entrepreneur. Pour en savoir
                    plus consultez notre{' '}
                    <a href="https://www.dougs.fr/politique-confidentialite" target="_blank">
                        politique de confidentialité.
                    </a>
                </MentionRGPD>

                <ErrorMessage>{errors.fetch && errors.fetch.message}</ErrorMessage>
                <StyledButton data-ga-send="event,link,click,contact-prise-rdv" type="submit" disabled={isSubmitting}>
                    {isSubmitting && <Spinner />}
                    Choisir un créneau →
                </StyledButton>
            </StyledForm>
        </div>
    );
}

export const RadioSelectorField = ({
    field,
    fieldState,
    hideMobile,
}: {
    field: FieldValues;
    fieldState: ControllerFieldState;
    hideMobile: boolean;
}) => {
    const Field = styled.div<{ error: boolean }>`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        ${breakpoint['$large-max']} {
            display: ${hideMobile ? 'none' : 'inherit'};
        }

        border-radius: inherit;
        border-style: solid;
        border-width: 1px;
        border-color: ${props => (!props.error ? 'rgba(0, 0, 0, 0.23)' : colors.$redWarning)};
        border-radius: 4px;
    `;

    const LabelSection = styled.div<{ error: boolean }>`
        color: ${props => (!props.error ? colors.$gray400 : colors.$redWarning)};
        height: 100%;
        background-color: ${colors.$gray100};
        padding: 16.5px 14px;
        margin-right: 24px;
        border-radius: 4px 0 0 4px;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 1rem;
    `;

    return (
        <Field error={!!fieldState.error}>
            <LabelSection error={!!fieldState.error}>Service recherché : </LabelSection>
            <RadioGroup value={field.value} name="Service recherché" row onChange={e => field.onChange(e.target.value)}>
                {formData.services.map(item => (
                    <FormControlLabel
                        key={item.value}
                        value={item.value}
                        label={item.radioLabel}
                        control={<Radio size="small" />}
                    />
                ))}
            </RadioGroup>
        </Field>
    );
};
